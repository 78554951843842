.cont_texto_cabeza_net{
  background-color: #0960c0;
  padding: 2%;
}
.texto_cabeza_net{
  color: #ffffff;
  font-family: 'Montserrat' !important;
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}
.row_cont_net{
  padding: 2%;
  background-color: #f6f6f6;
}
.contnedos_card_net{
  padding: 3% 3%;
  background-color: #ffffff;
  margin: 3% 3%;
  border-radius: 50px 0px 50px 0px;
  -webkit-box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2);
-moz-box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2);
box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2); 
}
.contnedos_card_net:hover{
  background-color: #eeeeee;
}
.nombre_net{
  font-family: 'Montserrat' !important;
  color: #0960c0;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin: 0px;
  text-transform: uppercase;
}
.empresa_net{
  font-family: 'Montserrat' !important;
  color:#606060;
  font-size: 14px;
  text-align: left;
  margin: 0px;
}
.cargo_net{
  font-family: 'Montserrat' !important;
  color:#606060;
  font-size: 14px;
  text-align: left;
  word-wrap:break-word;
}
.perfil_net_p{
  font-family: 'Montserrat' !important;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  margin: 0px;
  text-transform: uppercase;
}
.perfil_net{
  background-color:#0960c0;
  padding: 3px 5px;
}
.contenedor_perfiles{
  background-color: #ffffff;
  padding: 4%;
  margin: 2%;
  border-radius: 50px 0px 50px 0px;
  -webkit-box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2);
-moz-box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2);
box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2); 
}
.cont_titulo_perfil{
  border-bottom: 2px solid  rgba(96,96,96,0.8);
  margin: 0px;
  padding:0px;
}
.titulo_perfil{
  font-family: 'Montserrat' !important;
  color: #606060;
  text-align: left;
  margin: 0px;
  padding: 8px 0px 8px 5px;
  font-weight: bold;
  font-size: 16px;
}
.text_perfil_activo{
  font-family: 'Montserrat' !important;
  color: #0960c0;
  text-align: left;
  margin: 0px;
  padding: 8px 0px 8px 5px;
  font-size: 16px;
  cursor: pointer;
}
.text_perfil{
  font-family: 'Montserrat' !important;
  color: #606060;
  text-align: left;
  margin: 0px;
  padding: 8px 0px 8px 5px;
  font-size: 16px;
  cursor: pointer;
}
.paginas_num{
  font-size: 16px;
  color: #606060;
}
@media screen and (max-width:700px){
  .texto_cabeza_net{
    font-size: 18px;
  }
  .nombre_net{
    font-size: 14px;
  }
  .empresa_net{
    font-size: 14px;
  }
  .cargo_net{
    font-size: 14px;
  }
  .perfil_net_p{
    font-size: 14px;
  }
}