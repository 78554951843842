/* For demo */

.titles{
  text-decoration: none;
  color: #0d3159;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;;
  font-family: 'Montserrat', sans-serif;
}
.principals{
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
  background-color: #ffffff;
}
.titulo{
  font-size: 45px;
  line-height: 1.4;
  font-weight: bold;
}
.fecha-top{
  color: #f4b414;
  font-size: 40px;
  line-height: 1;
}

.subtitle{
  color: #ffffff;
  font-size: 24px;
  line-height: 1;
  margin-top: 3%;
}
.ponentes1{
  background-image: url('../../images/temp/img1200x600.png');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponentes2{
  background-image: url('../../images/temp/img1200x600.png');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponente{
  width: 100%;
}
.programa{
  background-image: url('../../images/temp/img1200x600.png');
  border-image-repeat: no-repeat;
  background-size: cover;
}
.divPrograma{
  background-color: #ffffff;
  text-align: left;
  line-height: 1;
}

.visualizar{
  border-bottom: 1px solid #000000;
}
.info-hora{
  color:#000000;
  font-size: 1.3em;
  font-family: 'Economica', sans-serif;
  font-weight: bold;
}
.entradas{
  background-image: url('../../images/temp/img1200x600.png');
  border-image-repeat: no-repeat;
  background-size: cover;
}
.divEntradas{
  text-align: right;
  line-height: 1;
}
.tituEntradas{
  color:#eb4b1c;
  margin: 3% 0% 0% 0%;
  font-size: 3em;
}
.textEntradas{
  color:#000000;
  font-size: 1.5em;
}
.tituLogos{
  border-bottom: 4px solid #000000;
  font-size: 3em;
  text-align: left;
  color: #000000;
}

.btn-registrar{
  width: 200px;
}
.principals{
  height: 1300px;
}
@media only screen and (max-width: 2700px) {
  .principals{
    height: 2350px!important;
  }
  .btn-registrar{
    width: 500px;
  }

}
@media only screen and (max-width: 1700px) {
  .principals{
    height: 1350px!important;
  }
  .btn-registrar{
    width: 250px;
  }
}
@media only screen and (max-width: 1300px) {
  .principals{
    height: 1250px!important;
  }
  .btn-registrar{
    width: 200px;
  }
}
@media only screen and (max-width: 990px) {
  .principals{
    height: 900px!important;
  }
  .btn-registrar{
    width: 150px;
  }
  
  .info-hora{
    font-size: 1.3em;
  }
  .info-fecha{
    font-size: 1.3em;
  }
  .info-nombre{
    font-size: 1.2em;
  }
  .info-descripcion{
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 700px) {
  .principals{
    height: 650px!important;
  }
 
  .btn-registrar{
    width: 100px;
  }
  .info-hora{
    font-size: 1.1em;
  }
  .info-fecha{
    font-size: 1.1em;
  }
  .info-nombre{
    font-size: 1em;
  }
  .info-descripcion{
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 500px) {
  .principals{
    height: 500px!important;
  }
}
@media only screen and (max-width: 400px) {
  .principals{
    height: 500px!important;
  }
}



