.contenedor_inical{
    position: relative;
}
.contenedor_banner_menu{
    padding-top: 120px;
}
.img_banner_inicial{
    width: 100%;
    position: relative;
}

.boton_registro_inicial{
    width: 150px;
    position: absolute;
    z-index: 99;
    bottom: 20%;
    right: 10%;
}

.boton_registro_calendar{
    width: 150px;
    position: absolute;
    z-index: 99;
    bottom: 20%;
    right: 30%;
}

.dudas_btn{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: left;
}
.costo_patrocinio_hoome{
    font-size: 28px;
    margin-bottom: 0px;
    text-align: center;
}
.costo_patrocinio_hoome_2{
    font-size: 22px;
}
.btn_patrocinio_hoome{
    font-size: 20px;
    background-color: #ffffff;
    color: #263259;
    border-radius: 20px;
    padding: 7px 15px;
    -webkit-box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
  -moz-box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
  box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
}
.datos_back{
    background-image: url('');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
}
.back_lider{
    background-image: url('../images/anual_2024/home/back_frase.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
}
.linea-con-circulos, .linea-con-circulos-2 {
    position: relative;
    width: 100%; /* Ancho de la línea */
    height: 1.5px; /* Grosor de la línea */
    background-color: #ffffff; /* Color de la línea */
    margin: 0px auto; /* Margen para centrar la línea */
  }
  
  .linea-con-circulos::before,
  .linea-con-circulos-2::after {
    content: ''; /* Se requiere para que los pseudoelementos se muestren */
    position: absolute;
    width: 10px; /* Diámetro del círculo */
    height: 10px; /* Diámetro del círculo */
    border-radius: 50%; /* Hace que el elemento sea un círculo */
    background-color: #ffffff; /* Color del círculo */
  }
  
  .linea-con-circulos::before {
    left: 0;
    top: -4px; /* Posiciona el círculo arriba de la línea */
  }
  
  .linea-con-circulos-2::after {
    right: 0;
    top: -4px; /* Posiciona el círculo arriba de la línea */
  }
  
@media screen and (max-width: 767px) {
    .boton_registro_inicial{
        width: 100px;
        position: absolute;
        z-index: 99;
        }

        .boton_registro_calendar{
            width: 100px;
            position: absolute;
            z-index: 99;
            }
            .contenedor_banner_menu{
                padding-top: 50px;
            }
            .costo_patrocinio_hoome{
                font-size: 16px;
                margin-bottom: 0px;
                text-align: center;
            }
            .costo_patrocinio_hoome_2{
                font-size: 14px;
            }
            .btn_patrocinio_hoome{
                font-size: 14px;
                border-radius: 20px;
                padding: 7px 15px;
            }

            .linea-con-circulos::before,
  .linea-con-circulos-2::after {
    content: ''; /* Se requiere para que los pseudoelementos se muestren */
    position: absolute;
    width: 5px; /* Diámetro del círculo */
    height: 5px; /* Diámetro del círculo */
    border-radius: 50%; /* Hace que el elemento sea un círculo */
    background-color: #ffffff; /* Color del círculo */
  }
  
  .linea-con-circulos::before {
    left: 0;
    top: -2px; /* Posiciona el círculo arriba de la línea */
  }
  
  .linea-con-circulos-2::after {
    right: 0;
    top: -2px; /* Posiciona el círculo arriba de la línea */
  }
  .back_lider{
    background-image: url('../images/anual_2024/home/back_frase.jpg');
    background-repeat: no-repeat;
    background-size:cover;
}
}


@media screen and (max-width: 565px) {
    .boton_registro_inicial{
        width: 60px;
        position: absolute;
        z-index: 99;
        bottom: 10%;
        }

        .boton_registro_calendar{
            width: 60px;
            position: absolute;
            z-index: 99;
            bottom: 10%;
            }
            .dudas_btn{
                font-size: 14px;
                text-align: center;
            }
}