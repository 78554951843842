.patro_plata{
    padding: 0px 60px;
    font-size: 32px;
    width: 100px;
    color: #9f9f9f;
    float:left;
    margin: 0px;
}

.patro_oro{
    padding: 0px 60px;
    font-size: 32px;
    width: 100px;
    color: #A3850B;
    float:left;
    margin: 0px;
}
.linea {
    margin:10px 30px;
    width:83%;    
    border-top:2px solid #A3850B;
    position: relative;
    top:15px;
    float:right;
    }
    .linea_2 {
        margin:10px 30px;
        width:77%;    
        border-top:2px solid #9f9f9f;
        position: relative;
        top:15px;
        float:right;
        }
    
  
@media screen and (max-width:700px){
    .patro_plata{
        padding: 5px 20px;
        font-size: 22px;
    }
    .patro_oro{
        padding: 5px 30px;
        font-size: 22px;
    }
    .linea {
        margin:10px 30px;
        width:50%;    
        border-top:2px solid #A3850B;
        position: relative;
        top:15px;
        float:right;
        }
        .linea_2 {
            margin:10px 30px;
            width:50%;    
            border-top:2px solid #9f9f9f;
            position: relative;
            top:15px;
            float:right;
            }
}