.texto_costos{
    font-size: 16px;
    text-align: left;
}
.texto_costos_grande{
    font-size: 22px;
    text-align: left;
    font-weight: bold;
}
.texto_costos_grande_2{
    font-size: 26px;
    text-align: left;
    font-weight: bold;
    line-height: 1.2;
}
.contenedor_costo{
    background-color: rgba(0,0,0,0.2);
    border-radius: 20px;
    text-align: left;
    padding: 25px 25px;
}
.datos_sub{
    font-size: 28px;
    text-align: center;
}
.transferen_text{
    font-size: 22px;
}
.block_titulo{
    font-size: 19px;
    font-weight: bold;
}
.block_info{
    font-size: 15px;
    font-weight: bold;
}
.texto_final_costos{
    font-size: 16px;
    text-align: center;
}
.back_costos_sec{
    background-image: url('../images/segunda_2024/PNG/lineasmoradas_costos.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position:0% 40%;
  background-attachment: fixed;
  
}
.back_datos{
    background-image: linear-gradient(to right, #d4e2f9, #edf0f9);

}
.cuadro_texto{
    text-align: center;
    font-size: 18px;
    line-height: 1.2;
    border: 2px solid #06cad4;
    padding: 15px 0px;
    border-radius: 50px;
}
.cuadro_texto_1{
    font-size: 18px;
    font-weight: 800;
}
@media screen and (max-width:767px){
    .back_costos_sec{
        background-image: url('');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position:0% 10%;
    }
    .texto_costos_grande{
        font-size: 16px;
        text-align: left;
    }
    .texto_costos_grande_2{
        font-size: 15px;
        text-align: left;
    }
    .texto_costos{
        font-size: 13px;
        text-align: left;
    }
    .datos_sub{
        font-size: 22px;
        text-align: center;
    }
    .transferen_text{
        font-size: 14px;
        text-decoration: underline;
    }
    .block_titulo{
        font-size: 15px;
        font-weight: bold;
    }
    .block_info{
        font-size: 13px;
        font-weight: bold;
    }
    .texto_final_costos{
        font-size: 14px;
        text-align: center;
    }
    .cuadro_texto_1{
        font-size: 14px;
    }
    .cuadro_texto{
        font-size: 12px;
    line-height: 1.1;
    border: 2px solid #06cad4;
    padding: 15px 10px;
    border-radius: 30px;
    }
}