.ant-modal-content{
  background-color: rgba(26, 26, 26, 0.9)!important;
}
/** **/
.contenedor_imagen_con {
  position: relative;
  padding: 0px 10px;
}

.imagen_con {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 50px 0px 50px 0px;
  -webkit-box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2);
-moz-box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2);
box-shadow: 5px 3px 4px 2px rgba(96,96,96,0.2); 
}

.overlay_con {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  right: 0;
  height: 100%;
  width: 95%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(38, 50, 89,0.8);
  border-radius: 50px 0px 50px 0px;
}

.contenedor_imagen_con:hover .overlay_con {
  opacity: 1;
}

.text_con {
  color: white;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 60%;
  left: 20%;
  -webkit-transform: translate(-20%, -20%);
  -ms-transform: translate(-20%, -20%);
  transform: translate(-20%, -20%);
  text-align: center;
}

.nombre_con{
  font-size: 15px!important;
  font-weight: bold;
  text-transform:uppercase;
  text-align: left;
}

.empresa_con{
  font-size: 12px;
  margin-bottom: 0px;
  text-align: left;
}

.cargo_con{
  font-size: 13px;
  margin-bottom: 0px;
  text-align: left;
}

.nombre_modal{
  font-size: 18px!important;
  color: #ffffff;
  padding-bottom: 5px;
  font-weight: bold;
  text-align: center;
}

.empresa_modal{
  font-size: 16px;
  color: #0ac2c2;
  text-align: center;
}

.cargo_modal{
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0px;
  text-align: center;
}
.semblanza_modal{
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0px;
  text-align: justify;
}
.btn_desc_con{
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
}

.contenedor_conferencista{
  padding: 3% 0% 0% 0%;
}
.contenedor_img_conferencista{
  padding: 0px 0px 5px 0px;
}
.img_conferencista{
  width: 80%;
}
.nombre_conferencista{
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-size: 34px;
  line-height: 30px;
  font-weight: bold;
  color: #414042;
  margin-bottom: 0px;
}
.puesto_conferencista{
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-size: 28px;
  color:#4067b0;
  margin-bottom: 0px;
  line-height: 1.2;

}
.p_text{
  text-align: left;
  margin:0px;
  padding:0px;
}
.conferencia_text{
  font-family: 'Montserrat', sans-serif;
  background-color:#bababb;
  color:#ffffff;
  font-size: 22px;
  padding: 2px 5px;
}
.parrafo_confeencista{
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
  color:#606060;
  font-size: 16px;
}

@media screen and (max-width:700px){
  .puesto_conferencista{
    font-size: 16px;
  }
  .conferencia_text{
    font-size: 14px;
  }
  .nombre_conferencista{
    font-size: 22px;
  }
  .parrafo_confeencista{
    font-size: 14px;
  }
}