.estilo_campos{
    color:#263259;
}

.ant-input{
    background-color: #ebf1fc!important;
    border: 0px solid #ebf1fc!important;

}
.ant-input-affix-wrapper{
    background-color: #ebf1fc!important;
    border: 0px solid #ebf1fc!important;
}

.nom-label{
    color:#606060!important;
    font-size: 18px!important;
}
.nom-label-cel{
    color:#538ae4!important;
    font-size: 18px!important;
}
.num-men{
    background-color: #538ae4;
    color:#ffffff;
    font-size: 18px;
}
.ant-form-item-required{
}
.agregar_persona{
    background-color: #263259;
    color:#ffffff;
    text-align: center;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    -webkit-box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
  -moz-box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
  box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
}
.quitar_persona{
    background-color: #06cad4;
    color:#ffffff;
    text-align: center;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    -webkit-box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
  -moz-box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
  box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
}
.registro_btn{
    background-color: #0960c0;
    color:#ffffff;
    text-align: center;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
}
.ayuda_brn{
    background-color: #ffffff;
    color:#263259;
    text-align: center;
    font-weight: bold;
    padding: 5px 35px;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
    -webkit-box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
  -moz-box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
  box-shadow: 5px 3px 4px 2px rgba(235, 241, 252,1);
}
@media only screen and (max-width: 500px) {
    .nom-label, .nom-label-cel, .num-men{
        font-size: 12px!important;
    }
    .ant-form-item-required{
        font-size: 12px!important;
    }
}
.transmision{
    width: 50%;
}

.select_telefono{
    width:100px;
}

.boton_recibir_codigo{
    color: #ffffff !important;
    background-color:#4067b0 !important;
    border: 3px solid #4067b0!important;
    font-weight: bold;
    cursor: pointer;
}

.boton_registrate{
    color: #538ae4 !important;
}

.boton_sin_login{
    background-color: #083280 !important;
    color: white !important;
}
.selector_radio_opcion{
    color:black;
}

.selector_radio_opcion:hover{
    color:#538ae4 !important;
}

.selector_radio_opcion:focus{
    color:#538ae4 !important;
}

