.back_hospedaje{
    padding: 0% 0% 3% 0%;
    /*background-attachment: fixed;*/
    background-color: #e2e2e2;
    background-size: 100%;
    background-position: center;
    background-image: url('../images/anual_2024/home/back_hospedaje.jpg');
  }
.text_safi{
    font-size: 24px;
    text-align: left;
    line-height: 1.2;
}
.text_safi_ubicacion{
    font-size: 13px;
    text-align: left;
}
.circulo_hospedaje{
    background-image: url('');
    background-size: 300px;
    background-repeat: no-repeat;
}
.back_dispar_2{
    background-color: rgba(52, 51, 51, 0.2);
}
.texto_hospedaje{
    font-size: 16px;
    text-align: left;
    padding: 10px 20px 0px 0px;
}
.texto_hospedaje_2{
    font-size: 15px;
    text-align: left;
    padding: 10px 20px;
}
.texto_hospedaje_3{
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px 0px 20px;
}
.texto_hospedaje_4{
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    padding: 0px 20px;
}
.links_hospedaje{
    font-size: 16px;
    text-align: center;
    font-weight: bold;
}
.hover_aqua:hover{
    color: #06cad4;
}
.icon_link_h{
    width: 50px;
}
@media screen and (max-width:767px){
    .circulo_hospedaje{
        background-size: 100px;
        background-repeat: no-repeat;
    }
    .text_safi{
        font-size: 12px;
        font-weight: bold;
    }
    .text_safi_ubicacion{
        font-size: 10px;
    }
    .texto_hospedaje{
        font-size: 14px;
    }
    .texto_hospedaje_2{
        font-size: 10px;
    }
    .texto_hospedaje_3{
        font-size: 15px;
    }
    .texto_hospedaje_4{
        font-size: 13px;
    }
    .links_hospedaje{
        font-size: 12px;
        padding-left: 10px;
        text-align: center;
    }
    .back_hospedaje{
        padding: 0% 0% 10% 0%;
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
      }
      .icon_link_h{
        width: 30px;
    }
}